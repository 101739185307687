<template>
  <div class="register-container">
    <!-- 左侧区域 -->
    <div class="login-left-box">
      <img src="@/assets/imgs/logo.png" />
    </div>

    <!-- 右侧区域 -->
    <div class="login-right-box">
      <!-- 表单区域 -->
      <div class="form-box">
        <el-form
          :model="form"
          :rules="formRules"
          ref="formRef"
        >
          <!-- 昵称 -->
          <el-form-item
            prop="account"
            class="account-item"
          >
            <el-input
              v-model.trim="form.account"
              prefix-icon="iconfont icon-zhanghao"
              :placeholder="$t('word1015')"
            >
            </el-input>
          </el-form-item>
          <!-- 验证码（4位数） -->
          <el-form-item
            prop="verifyCode"
            class="verify-code-item"
            :error="verifyCodeError"
          >
            <el-input
              v-model="form.verifyCode"
              prefix-icon="iconfont icon-yanzhengyanzhengma"
              :placeholder="$t('word1016')"
              maxlength="4"
              @input="form.verifyCode = form.verifyCode.replace(/\D/g, '')"
            >
            </el-input>

            <div
              class="verify-code-box"
              @click="getVerifyCode4"
            >
              <SIdentify :identifyCode="identifyCode"></SIdentify>
            </div>
          </el-form-item>
          <!-- 邮箱 -->
          <el-form-item
            prop="phone"
            class="phone-item"
            :error="phoneError"
          >
            <el-input
              v-model="form.phone"
              prefix-icon="iconfont icon-youxiang"
              :placeholder="$t('word1002')"
            >
              <el-button
                slot="append"
                class="verify-code"
                :disabled="btnConfig.disabled"
                @click="getVerifyCode"
              >{{ btnConfig.title }}</el-button>
            </el-input>
          </el-form-item>
          <!-- 验证码（6位数） -->
          <el-form-item
            prop="code"
            class="code-item"
            :error="codeError"
          >
            <el-input
              v-model="form.code"
              prefix-icon="iconfont icon-yanzhengma"
              :placeholder="$t('word1027')"
              maxlength="6"
              @input="form.code = form.code.replace(/\D/g, '')"
            >
            </el-input>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item
            prop="password"
            class="password-item"
          >
            <el-input
              v-model.trim="form.password"
              prefix-icon="iconfont icon-mima"
              :placeholder="$t('word1003')"
              show-password
            >
            </el-input>
          </el-form-item>
          <!-- 重复 -->
          <el-form-item
            prop="passwordRepeat"
            class="password-repeat-item"
          >
            <el-input
              v-model.trim="form.passwordRepeat"
              prefix-icon="iconfont icon-repeat"
              :placeholder="$t('word1018')"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item class="btn-item">
            <el-button
              class="btn-box"
              @click="register"
            >{{ $t('word1006') }}</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 底部区域 -->
      <!-- <div class="bottom-box">
        <span>{{ $t('word1007') }}</span>
        <span>{{ $t('word1008') }}</span>
        <span>{{ $t('word1009') }}</span>
      </div> -->

      <!-- 语言切换 -->
      <div class="language-box">
        <el-select
          v-model="lang.value"
          popper-class="public-select-dropdown"
          @change="langChange"
        >
          <el-option
            v-for="item in lang.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js'
import SIdentify from '@/components/SIdentify.vue'
import { getVerifyCode4, getVerifyCode6, register } from '@/api/api.js'

export default {
  name: 'Register',
  mixins: [mixin],
  components: {
    SIdentify
  },
  data() {
    // 检查验证码
    const checkVerifyCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('word1019')))
      } else if (value !== this.identifyCode) {
        callback(new Error(this.$t('word1020')))
      } else {
        callback()
      }
    }

    // 检查密码是否一致
    const checkPassword = (rule, value, callback) => {
      if (this.form.password === this.form.passwordRepeat) callback()
      else callback(new Error(this.$t('word1021')))
    }

    return {
      // 表单对象
      form: {
        account: '',
        verifyCode: '', // 验证码（4位数）
        phone: '',
        code: '', // 短信验证码（6位数）
        password: '',
        passwordRepeat: ''
      },
      // 表单校验对象
      formRules: {
        account: [{ required: true, message: this.$t('word1022'), trigger: 'blur' }],
        verifyCode: [{ validator: checkVerifyCode, required: true, trigger: 'blur' }],
        phone: [{ required: true, message: this.$t('word1023'), trigger: 'blur' }],
        code: [{ required: true, message: this.$t('word1028'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('word1011'), trigger: 'blur' }],
        passwordRepeat: [
          { required: true, message: this.$t('word1024'), trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' }
        ]
      },
      verifyCodeError: '', // 验证码插件错误提示
      phoneError: '', // 手机号错误提示
      codeError: '', // 验证码错误提示
      // 按钮配置
      btnConfig: {
        title: this.$t('word1017'),
        disabled: false
      },
      // 验证码插件
      identifyCode: '',

      // 语言数据
      lang: {
        options: [
          {
            value: 'zh-CN',
            label: '简体中文'
          },
          {
            value: 'en-US',
            label: 'English'
          },
          {
            value: 'bahasa-Indonesia',
            label: 'Bahasa Indonesia'
          }
        ],
        value: sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'zh-CN'
      }
    }
  },
  created() {
    this.getVerifyCode4()
  },
  methods: {
    // 获取验证码（4位数）
    getVerifyCode4() {
      getVerifyCode4({}).then(res => {
        if (res.code === 200) {
          this.identifyCode = res.msg
        }
      })
    },

    // 获取验证码
    getVerifyCode() {
      // 判断验证码是否填写、或填写正确
      if (!this.form.verifyCode) return (this.verifyCodeError = this.$t('word1019'))
      if (this.form.verifyCode !== this.identifyCode) return (this.verifyCodeError = this.$t('word1020'))

      if (!this.form.phone) return (this.phoneError = this.$t('word1023'))

      getVerifyCode6({
        useFor: '0',
        areaCode: '86',
        securityCode: this.form.verifyCode,
        mobile: this.form.phone
      }).then(res => {
        if (res.code === 200) {
          this.verifyCodeError = ''
          this.phoneError = ''
          this.countDown()
        }
      })
    },

    // 倒计时
    countDown() {
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.btnConfig.title = this.$t('word1017')
          this.btnConfig.disabled = false
        } else {
          this.btnConfig.title = `${time}${this.$t('word1025')}`
          this.btnConfig.disabled = true
          time--
        }
      }, 1000)
    },

    // 注册
    register() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const par = this.getUrlVal()

          const { account, phone, code, password } = this.form
          const params = {
            // firmId 是从地址栏中取
            firmId: par,
            countryId: 1,
            areaCode: '86',
            nickname: account,
            mobile: phone,
            verifyCode: code,
            password
          }
          register(params).then(res => {
            if (res.code === 200) {
              const par = this.getUrlVal()
              this.$message.success({ message: this.$t('word1026'), duration: 1500 })
              this.$router.push({ name: 'login', query: { firmId: par } })
              this.codeError = ''
            }
          })
        } else {
          return false
        }
      })
    },

    // 获取地址栏参数
    getUrlVal() {
      const url = window.location.href // 获取当前url
      const endUrl = url.split('#')[1] // 获取 #/ 之后的字符串
      const cs = endUrl.split('?')[1] // 获取 ? 之后的参数字符串
      const par = cs.split('=')[1] // 获取 = 之后的值
      return par
    },

    // 语言切换
    langChange(value) {
      const lang = value === 'zh-CN' ? 'zh_cn' : value === 'en-US' ? 'en' : value
      this.setCookie('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE', lang)
      sessionStorage.setItem('locale', value)
      window.location.reload() // 刷新页面
    }
  }
}
</script>

<style lang="scss" scoped>
.register-container {
  display: flex;
  height: 100%;

  // 左侧区域
  .login-left-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
    background-color: #1c252c;
  }

  // 右侧区域
  .login-right-box {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url('../assets/imgs/login_right_img.png') center center no-repeat;
    background-size: 100% 100%;

    // 表单区域
    .form-box {
      width: 400px;

      // 重写 elementUI 自带样式
      ::v-deep .el-form {
        .el-form-item {
          margin-bottom: 40px;
        }
        .el-form-item:last-child {
          margin-bottom: 0;
        }

        // 验证码插件
        .verify-code-item {
          .el-form-item__content {
            display: flex;
          }
        }

        .el-input-group__append {
          background-color: #3f310c;
          .verify-code {
            color: #ffffff;
            font-size: 18px;
          }
          .el-button {
            background-color: #3f310c;
          }
        }
        .btn-item {
          .el-form-item__content {
            text-align: center;
          }
          .btn-box {
            background-color: #3f310c;
            padding: 10px 40px;
            color: #ffffff;
            font-size: 18px;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        .el-form-item__content {
          line-height: 50px;
        }
        .el-input__prefix {
          color: #ffffff;
          left: 15px;
          .iconfont {
            font-size: 25px;
          }
          .el-input__icon {
            line-height: 50px;
          }
        }
        .el-input__inner {
          height: 50px;
          line-height: 50px;
          background-color: #77683f;
          padding-left: 50px;
          color: #ffffff;
          font-size: 18px;
        }
        .el-input__inner::placeholder {
          color: #ffffff;
          font-size: 18px;
        }
      }
    }

    // 底部区域
    .bottom-box {
      position: absolute;
      bottom: 20px;
      height: 40px;
      line-height: 40px;
      span {
        padding: 5px 10px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    // 语言切换
    .language-box {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 160px;
    }
  }
}
</style>

